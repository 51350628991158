<template>
  <div class="resume-container">
    <div class="resume-section">
      <AppDivider />
      <p class="m-0">
        Soy un desarrollador apasionado por la tecnología y la programación, siempre en busca de nuevos retos y
        soluciones creativas. Me encanta experimentar con diferentes tecnologías y lenguajes de programación para crear
        proyectos interesantes y útiles.
      </p>
      <AppDivider />
    </div>
  </div>
</template>

<script>
import AppDivider from 'primevue/divider';

export default {
  name: 'ResumeSection',
  components: {
    AppDivider
  }
};
</script>

<style scoped>
.resume-container {
  display: flex;
  justify-content: center;
}

.resume-section {
  width: 70%;
  text-align: center;
}

.m-0 {
  margin: 0;
}
</style>
