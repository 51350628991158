import { createApp } from 'vue';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import Divider from 'primevue/divider';
import Tooltip from 'primevue/tooltip';


import 'primeicons/primeicons.css'


const app = createApp(App);

app.use(PrimeVue, {
    theme: {
        preset: Aura
    }
});
app.component('AppDivider', Divider);
app.directive('tooltip', Tooltip);

app.mount('#app');
