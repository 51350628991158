<template>
    <footer class="footer">
        <div class="footer-content">
            <div class="footer-logo">
                <img src="@/assets/logoGiovannidev.jpg" alt="Logo" />
            </div>
            <div class="footer-social">
                <a href="https://www.linkedin.com/in/giovannidev/" target="_blank"><i class="pi pi-linkedin"></i></a>
                <a href="https://github.com/lonkonao/" target="_blank"><i class="pi pi-github"></i></a>
            </div>
            <div class="footer-copyright">
                <p>&copy; 2024 Giovanni Caceres Rubio</p>
                <p>Esta web fue realizada 100% con Vue <img src="@/assets/logo.png" alt="Vue Logo"
                        style="height: 20px;" /></p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'FooterSeccion'
};
</script>

<style scoped>
.footer {
    background-color: #1A1606;
    color: #FFFFFF;
    padding: 20px;
    text-align: center;
}

.footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.footer-logo img {
    width: 100px;
    height: auto;
}

.footer-summary {
    max-width: 600px;
    text-align: center;
}

.footer-social a {
    margin: 0 10px;
    font-size: 24px;
    color: #EF5C08;
}

.footer-social a:hover {
    color: #CA2D04;
}

.footer-copyright {
    font-size: 14px;
    color: #B0B0B0;
}
</style>
