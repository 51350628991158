<template>
  <div id="app">
    <ProfileHeader />
    <ResumeSection />
    <ProyectosColaboraciones />
    <FooterSeccion />
  </div>
</template>

<script>
import ProfileHeader from './components/ProfileHeader.vue';
import ResumeSection from './components/ResumeSection.vue';
import ProyectosColaboraciones from './components/ProyectosColaboraciones.vue';
import FooterSeccion from './components/FooterSeccion.vue';

export default {
  name: 'App',
  components: {
    ProfileHeader,
    ResumeSection,
    ProyectosColaboraciones,
    FooterSeccion
  }
};
</script>

<style>
body {
  background-color: #1A1A1A;
  color: #D3D3D3;
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
}

:root {
  --color-one: #EF5C08;
  --color-two: #1A1606;
  --bg-size: 400%;
}
</style>
