<template>
  <div class="card-container">
    <div class="card">
      <img class="card-image" :src="imageSrc" alt="Project Image" />
      <div class="card-content">
        <h2 class="card-title">{{ title }}</h2>
        <p class="card-subtitle">{{ subtitle }}</p>
        <div class="card-section">
          <span class="card-label">Motivación</span>
          <p class="card-value">{{ motivation }}</p>
        </div>
        <div class="card-section">
          <span class="card-label">Tecnologías</span>
          <div class="card-technologies">
            <template v-for="(tech, index) in technologies" :key="index">
              <i v-if="tech.icon" :class="tech.icon" v-tooltip.top="{ value: tech.name }"></i>
              <img v-else-if="tech.image" :src="tech.image" :alt="tech.name" class="tech-image"
                v-tooltip.top="{ value: tech.name }" />
            </template>
          </div>
        </div>
        <div class="card-buttons">
          <Button label="Github" icon="pi pi-github" class="p-button-secondary github-button" :href="githubLink"
            :disabled="!githubLink" v-tooltip.top="!githubLink ? { value: githubTooltip } : {}" />
          <Button label="Visitar" icon="pi pi-external-link" class="p-button-secondary visit-button" :href="visitLink"
            :disabled="!visitLink" v-tooltip.top="!visitLink ? { value: visitTooltip } : {}" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';
import Tooltip from 'primevue/tooltip';

export default {
  name: 'ProjectCard',
  components: {
    Button
  },
  directives: {
    tooltip: Tooltip
  },
  props: {
    imageSrc: String,
    title: String,
    subtitle: String,
    type: String,
    motivation: String,
    technologies: Array,
    githubLink: String,
    visitLink: String,
    githubTooltip: String,
    visitTooltip: String
  }
};
</script>

<style scoped>
.card-container {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.card {
  background-color: #1A1606;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 300px;
  color: #FFFFFF;
  padding: 20px;
  text-align: center;
}

.card-image {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
}

.card-content {
  margin-top: 20px;
}

.card-title {
  font-size: 1.5rem;
  margin: 10px 0;
  color: #CA2D04;
}

.card-subtitle {
  font-size: 1rem;
  color: #D3D3D3;
  margin: 5px 0 20px;
}

.card-section {
  margin-bottom: 10px;
}

.card-label {
  font-weight: bold;
  color: #CA2D04;
}

.card-value {
  margin: 0;
  color: #D3D3D3;
}

.card-technologies {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.card-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.p-button-secondary {
  background-color: #EF5C08;
  border: none;
  margin: 0 10px;
}

.p-button-secondary .p-button-icon {
  color: #EF5C08;
}

.p-button-secondary:hover:not(.p-disabled) {
  background-color: #000000;
  color: #ffffff;
}

.p-button-secondary:hover:not(.p-disabled) .p-button-icon {
  color: #ffffff;
}

.tech-image {
  width: 24px;
  height: 24px;
  display: inline-block;
  object-fit: cover;
  vertical-align: middle;
}

.card-technologies {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}
</style>
