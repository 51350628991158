<template>
  <div class="profile-header">
    <div class="profile-content">
      <div class="profile-picture">
        <img src="@/assets/yop.jpeg" alt="Profile Picture">
        <span class="open-to-work">Open to work</span>
        <div class="social-links">
          <a href="https://www.linkedin.com/in/giovannidev/" target="_blank" style="width: 20px;height: 20px;">
            <Button icon="pi pi-linkedin"
              class="p-button-icon-only p-button-secondary p-button-rounded p-button-text p-button-lg"
              aria-label="LinkedIn" />
          </a>
          <a href="https://github.com/lonkonao/" target="_blank">
            <Button icon="pi pi-github"
              class="p-button-icon-only p-button-secondary p-button-rounded p-button-text p-button-lg"
              aria-label="Github" />
          </a>
        </div>
      </div>
      <div class="profile-details">
        <h1>Giovanni Caceres Rubio</h1>
        <h2 class="gradient-dark">Developer Engineer</h2>
        <p>Consultor Dev, docente y estratega en redes sociales.</p>
        <div class="actions">
          <a href="@/assets/cv.pdf" target="_blank" download>
            <Button icon="pi pi-download" label="Descargar CV"
              class="p-button-secondary p-button-outlined text mr-2 mb-2" aria-label="CV Actualizado" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  name: 'ProfileHeader',
  components: {
    Button
  }
};
</script>

<style scoped>
.profile-header {
  text-align: center;
  color: #D3D3D3;
  background-color: #1A1A1A;
  padding: 20px;
  border-radius: 10px;
}

.profile-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-picture {
  position: relative;
  margin-right: 20px;
}

.profile-picture img {
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.open-to-work {
  position: absolute;
  bottom: 45px;
  left: -24%;
  transform: translateX(30%);
  background-color: #CA2D04;
  color: #1A1606;
  border-radius: 12px;
  padding: 2px 8px;
  font-size: 15px;
  font-weight: bold;
}

.social-links {
  margin-top: -10px;
  display: flex;
  justify-content: center;
  gap: 5px;
}

.profile-details {
  text-align: left;
  line-height: 1.2;

}

h1,
h2,
p {
  margin: 0;
}

.gradient-dark {
  animation: move-bg 5s linear infinite;
  background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-one)) 0 0 / var(--bg-size) 100%;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 3rem;
  -webkit-text-fill-color: transparent;
  --bg-size: 400%;
  --color-one: #EF5C08;
  --color-two: #1A1606;
}

@keyframes move-bg {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 0;
  }
}

.text-4xl {
  font-size: 2.25rem;
}

.text-primary {
  color: #7e57c2;
}

.font-bold {
  font-weight: bold;
}

.text-700 {
  color: #b0bec5;
}

.p-button {
  margin: 0 10px;
}
</style>
