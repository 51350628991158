<template>
  <div class="projects-container">
    <h2 class="section-title">Proyectos y Colaboraciones</h2>
    <div class="cards-wrapper">
      <ProjectCard v-for="(project, index) in projects" :key="index" :imageSrc="project.imageSrc" :title="project.title"
        :subtitle="project.subtitle" :type="project.type" :motivation="project.motivation"
        :technologies="project.technologies" :githubLink="project.githubLink" :visitLink="project.visitLink"
        :githubTooltip="project.githubTooltip" :visitTooltip="project.visitTooltip" />
    </div>
  </div>
</template>

<script>
import ProjectCard from './ProjectCard.vue';

export default {
  name: 'ProyectosColaboraciones',
  components: {
    ProjectCard
  },
  data() {
    return {
      projects: [
        {
          imageSrc: require('@/assets/img/proyect/itil.png'),
          title: 'Consulta Notas',
          subtitle: 'Sistema de notas para alumnos de la asignatura de Itil',
          motivation: 'Problemas plataforma Oficial ',
          technologies: [
            { image: require('@/assets/img/logos/php.png'), name: 'PHP' },
            { image: require('@/assets/img/logos/json.svg'), name: 'JSON' },
          ],
          githubLink: 'https://github.com/lonkonao/WebNotasItil',
          visitLink: '',
          githubTooltip: 'Repositorio no disponible por motivos de privacidad',
          visitTooltip: 'No disponible por Ciclo Cerrado'
        },
        {
          imageSrc: require('@/assets/img/proyect/mdonihue.png'),
          title: 'Web Oficial Municipalidad Doñihue',
          subtitle: 'Web Oficial de la Municipalidad de Doñihue',
          motivation: 'creación de una web oficial para la municipalidad de Doñihue',
          technologies: [
            { image: require('@/assets/img/logos/laravel.png'), name: 'Laravel' },
            { image: require('@/assets/img/logos/docker.png'), name: 'Docker' },
            { image: require('@/assets/img/logos/ghactions.png'), name: 'GitHub Actions' },
          ],
          githubLink: '',
          visitLink: 'https://mdonihue.cl/',
          githubTooltip: 'Repositorio no disponible por motivos de privacidad',
          visitTooltip: 'No disponible por abandono'
        },
        {
          imageSrc: require('@/assets/img/proyect/dockerLaravelVite.jpg'),
          title: 'laravel+docker',
          subtitle: 'Este repositorio contiene una aplicación Laravel configurada para ejecutarse en un entorno Docker',
          motivation: 'Esto es con la finalidad de trabajar sin instalar nada más que Docker.',
          technologies: [
            { image: require('@/assets/img/logos/docker.png'), name: 'PHP' },
            { image: require('@/assets/img/logos/laravel.png'), name: 'JSON' },
          ],
          githubLink: 'https://github.com/lonkonao/skeleton_laravel_vite_docker',
          visitLink: '',
          githubTooltip: 'Repositorio no disponible por motivos de privacidad',
          visitTooltip: 'No Aplica'
        },
        {
          imageSrc: require('@/assets/img/proyect/appDonihue.png'),
          title: 'App Municipalidad Doñihue',
          subtitle: 'App para la municipalidad de Doñihue - No lanzada aun',
          motivation: 'Creación de una app para la municipalidad de Doñihue',
          technologies: [
            { image: require('@/assets/img/logos/ionic.png'), name: 'Ionic' },
            { image: require('@/assets/img/logos/react.png'), name: 'React' },
            { image: require('@/assets/img/logos/ghactions.png'), name: 'GitHub Actions' },
            { image: require('@/assets/img/logos/api.png'), name: 'API' },
          ],
          githubLink: '',
          visitLink: '',
          githubTooltip: 'Repositorio no disponible por motivos de privacidad',
          visitTooltip: 'No Aplica'
        },
        {
          imageSrc: require('@/assets/img/proyect/remediosC3.jpg'),
          title: 'RemediosC3 - Deprecated',
          subtitle: 'Sistema de agendamiento de retiro de medicamentos en farmacia C3- Rancagua',
          motivation: 'Iniciativa para evitar realizar fila para el retiro de medicamentos. Agendando una cita según calendario de retiro',
          technologies: [
            { image: require('@/assets/img/logos/php.png'), name: 'PHP' },
            { image: require('@/assets/img/logos/mysql.png'), name: 'MySQL' },
          ],
          githubLink: '',
          visitLink: '',
          githubTooltip: 'Repositorio no disponible por motivos de privacidad',
          visitTooltip: 'No disponible por abandono'
        },
        {
          imageSrc: require('@/assets/img/proyect/validacionrut.gif'),
          title: 'Validacion y formateo de RUT',
          subtitle: 'valida que el rut sea verdadero y lo formatea',
          motivation: 'Uso constante de validación de rut en proyectos',
          technologies: [
            { image: require('@/assets/img/logos/html5.svg'), name: 'HTML5' },
            { image: require('@/assets/img/logos/js.svg'), name: 'Js' },
          ],
          githubLink: 'https://github.com/lonkonao/validacion-rut-chileno',
          visitLink: '',
          githubTooltip: 'Repositorio no disponible por motivos de privacidad',
          visitTooltip: 'No Aplica'
        },
        {
          imageSrc: require('@/assets/img/proyect/parking.jpeg'),
          title: 'Sistema de estacionamiento - Deprecated',
          subtitle: 'Entrega de ingresos monetarios por turnos con voucher de nombre y horarios',
          motivation: 'Creado por la necesidad de llevar un control de manera remota de un estacionamiento',
          technologies: [
            { image: require('@/assets/img/logos/html5.svg'), name: 'HTML5' },
            { image: require('@/assets/img/logos/js.svg'), name: 'Js' },
            { image: require('@/assets/img/logos/laravel.png'), name: 'Laravel' },
            { image: require('@/assets/img/logos/mysql.png'), name: 'MySQL' },
          ],
          githubLink: 'https://github.com/lonkonao/estacionamiento',
          visitLink: '',
          githubTooltip: 'Repositorio no disponible por motivos de privacidad',
          visitTooltip: 'No Aplica'
        },
      ]
    };
  }
};
</script>

<style scoped>
.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.section-title {
  font-size: 2rem;
  color: #EF5C08;
  margin-bottom: 20px;
}

.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /*gap: 20px;*/
}

.card-container {
  flex: 1 1 calc(25% - 40px);
  display: flex;
  justify-content: center;
  max-width: 40%;
}

@media (max-width: 800px) {
  .card-container {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
</style>
